var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('taxRange.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('taxRange.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('addNew')))])],1),_c('div',{staticClass:"col-md-2 tw-flex"},[_c('label',{staticClass:"col-label form-label tw-mt-1"},[_vm._v(_vm._s(_vm.$t('taxRange.filter')))]),_c('div',{staticClass:"col-md-10"},[_c('Select',{staticClass:" tw-ml-5",model:{value:(_vm.tax_range_id),callback:function ($$v) {_vm.tax_range_id=$$v},expression:"tax_range_id"}},_vm._l((_vm.taxRangeMains),function(taxRangeMain,index){return _c('Option',{key:index,attrs:{"value":taxRangeMain.id,"label":taxRangeMain.effective_year}},[_vm._v(_vm._s(taxRangeMain.effective_year)+" ")])}),1)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 tw-border-r-4 tw-border-gray-300"},[_c('div',[_c('from-action',{ref:"form_action",attrs:{"resources":_vm.resources[0]},on:{"fetchData":_vm.fetchData}})],1)]),_c('div',{staticClass:"col-md-9"},[_c('Table',{attrs:{"columns":_vm.columns,"loading":_vm.loading,"max-height":"700","data":_vm.taxRanges,"stripe":""},scopedSlots:_vm._u([{key:"from_amount",fn:function(ref){
        var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.from_amount),expression:"row.from_amount"}],staticClass:"form-control only-border-bottom",attrs:{"type":"number"},domProps:{"value":(row.from_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "from_amount", $event.target.value)}}})]}},{key:"to_amount",fn:function(ref){
        var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.to_amount),expression:"row.to_amount"}],staticClass:"form-control only-border-bottom",staticStyle:{},attrs:{"type":"number"},domProps:{"value":(row.to_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "to_amount", $event.target.value)}}})]}},{key:"percentage",fn:function(ref){
        var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.percentage),expression:"row.percentage"}],staticClass:"form-control only-border-bottom",attrs:{"type":"number"},domProps:{"value":(row.percentage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "percentage", $event.target.value)}}})]}},{key:"amount_exclude",fn:function(ref){
        var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.amount_exclude),expression:"row.amount_exclude"}],staticClass:"form-control only-border-bottom",attrs:{"type":"text","readonly":"readonly"},domProps:{"value":(row.amount_exclude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "amount_exclude", $event.target.value)}}})]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }