<template>
    <div>
        <div class=" tw-border-b-2 tw-bg-gray-100 tw-p-2">
            <label class=" tw-text-sm tw-text-black">{{
                $t('taxRange.actionForm')
            }}</label>
        </div>
        <div>
            <ts-panel-wrapper>
                <div class="mb-3">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('taxRange.effectiveDate')
                    }}</label>
                    <div>
                        <date-Picker
                            class="col-md-12"
                            :value="model.effective_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select year"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('effective_year')
                            }"
                        ></date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('effective_year')"
                        >
                            {{ errors.first('effective_year') }}
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('taxRange.kidDependent')
                    }}</label>
                    <div>
                        <input
                            :value="model.amount_without_tax"
                            type="number"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('amount_without_tax')
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('amount_without_tax')"
                        >
                            {{ errors.first('amount_without_tax') }}
                        </div>
                    </div>
                </div>
                <div>
                    <Checkbox v-model="model.is_active">{{
                        $t('taxRange.active')
                    }}</Checkbox>
                </div>
            </ts-panel-wrapper>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh tw-p-3">
                    <ts-button
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'

export default {
    name: 'tax-range-create',
    props: ['resources'],
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                effective_year: null,
                amount_without_tax: null,
                is_active: false,
                details: []
            }
        }
    },

    methods: {
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/taxRange/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        cycleYearChange (value) {
            this.model.effective_year = value
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'TAX RANGE',
                desc: not.text
            })
        },
        setData () {
            this.model.effective_year = this.resources.effective_year
            this.model.amount_without_tax = this.resources.amount_without_tax
            this.model.is_active = this.resources.is_active
            this.model.details = this.resources.tax_range
        },
        clearInput () {
            this.errors = new Errors()
            this.model.effective_year = null
            this.model.amount_without_tax = false
            this.model.is_active = false
        }
    },
    watch: {
        resources: {
            handler () {
                this.setData()
            },
            deep: true
        }
    }
}
</script>
