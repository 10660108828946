<template>
    <div>
        <ts-page-title
            :title="$t('taxRange.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('taxRange.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div>
                    <Button type="info" ghost @click="addNew">{{
                        $t('addNew')
                    }}</Button>
                </div>
                <div class="col-md-2 tw-flex">
                    <label class="col-label form-label tw-mt-1">{{
                        $t('taxRange.filter')
                    }}</label>
                    <div class="col-md-10">
                        <Select class=" tw-ml-5" v-model="tax_range_id">
                            <Option
                                v-for="(taxRangeMain, index) in taxRangeMains"
                                :value="taxRangeMain.id"
                                :key="index"
                                :label="taxRangeMain.effective_year"
                                >{{ taxRangeMain.effective_year }}
                            </Option>
                        </Select>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="row">
                <div class="col-md-3 tw-border-r-4 tw-border-gray-300">
                    <div>
                        <from-action
                            ref="form_action"
                            @fetchData="fetchData"
                            :resources="resources[0]"
                        />
                    </div>
                </div>
                <div class="col-md-9">
                    <Table
                        :columns="columns"
                        :loading="loading"
                        max-height="700"
                        :data="taxRanges"
                        stripe
                    >
                        <template slot-scope="{ row }" slot="from_amount">
                            <input
                                type="number"
                                v-model="row.from_amount"
                                class="form-control only-border-bottom"
                            />
                        </template>

                        <template slot-scope="{ row }" slot="to_amount">
                            <input
                                type="number"
                                v-model="row.to_amount"
                                class="form-control only-border-bottom"
                                style="border-radius"
                            />
                        </template>

                        <template slot-scope="{ row }" slot="percentage">
                            <input
                                type="number"
                                v-model="row.percentage"
                                class="form-control only-border-bottom"
                            />
                        </template>

                        <template slot-scope="{ row }" slot="amount_exclude">
                            <input
                                class="form-control only-border-bottom"
                                type="text"
                                v-model="row.amount_exclude"
                                readonly="readonly"
                            />
                        </template>
                    </Table>
                    <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                        <ts-i-pagination
                            v-model="pagination"
                            @navigate="fetchData"
                        ></ts-i-pagination>
                    </div>
                </div>
            </div>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from './form.vue'
import { mapState, mapActions } from 'vuex'
import { head } from 'lodash'

export default {
    name: 'tax-rang',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            tax_range_id: null
        }
    },
    computed: {
        ...mapState('payroll/taxRange', ['resources', 'pagination']),
        taxRangeMains () {
            return this.$store.state.payroll.taxRange.taxRangeMains
        },
        columns () {
            return [
                {
                    title: this.$t('taxRange.amountFrom'),
                    slot: 'from_amount'
                },
                {
                    title: this.$t('taxRange.amountTO'),
                    slot: 'to_amount',
                    align: 'center'
                },
                {
                    title: this.$t('taxRange.percentage'),
                    slot: 'percentage',
                    align: 'center'
                },
                {
                    title: this.$t('taxRange.amountExclude'),
                    slot: 'amount_exclude',
                    align: 'center'
                }
            ]
        },
        taxRanges () {
            return head(this.resources) ? head(this.resources).tax_range : []
        }
    },
    methods: {
        addNew () {
            this.$refs.form_action.clearInput()
        },
        ...mapActions('payroll/taxRange', ['getTaxRangeFilter']),
        fetchResource () {
            this.loading = true
            this.getTaxRangeFilter()
                .then(() => {
                    this.tax_range_id = this.taxRangeMains.find(
                        el => el.is_active == true
                    ).id
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/taxRange/fetch', attributes)
                .then(() => {
                    this.is_active = this.resources.find(
                        el => el.is_active == true
                    )
                })
                .catch(error => {
                    this.loading = false
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'TAX RANGE',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    },
    watch: {
        tax_range_id: function (val) {
            if (val) {
                this.fetchData({
                    filter: {
                        id: val
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
table tbody tr td {
    border: none !important;
}
.only-border-bottom {
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
